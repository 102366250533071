import { createTextMask, createNumberMask } from 'redux-form-input-masks';

export const API_ROOT = 'https://api.monospace.au';
export const WEB_ROOT = 'https://admin.monospace.au';
export const GOOGLE_MAP_API_KEY = "AIzaSyDZxA_Mi5SiDCYv-QpfR67oJ6ztNX7fJ6M";

export function _dispatch(nextState, rerender = false, compName = null) {
  rerender = rerender
  ? new Date().getTime()
  : nextState.status;
  return {
  ...nextState,
  status: rerender,
  compName
  }
  }
export const DEFAULT_AVATAR = '/assets/images/damien.png';
export const CHAT_MESSAGE_STATUS = {
	SENDING: 1,
	SENT: 2,
	FAILED: 3,
	EDITED: 4
};
export const BASE_URL_IO = 'https://api.monospace.au:3011/';
export const WEATHER_API_KEY="05939da6c5mshed0b70d15b9cb2dp111f18jsnb32a6d1d84a1"
export const  WEATHER_URL="https://weatherapi-com.p.rapidapi.com/"
export const  weather_icon_array=['01d','01n','02d','02n','03d','03n','04d','04n','09d','09n','10d','10n','11d','11n','13d','13n','50d','50n']

export const TICKET_STATUS = [
  { value: 0, label: 'Open', color: '#17CB75', background: '#E3F5ED' },
  { value: 1, label: 'In Progress', color: '#FFA53B', background: '#F9EFE3' },
  { value: 2, label: 'Overdue', color: '#573BFF', background: '#E2EAFF' },
  { value: 3, label: 'Closed', color: '#FF3B5E', background: '#FEF1F4' },
];

export const BOOKING_STATUS = [
  { value: 0, label: 'Pending' },
  { value: 1, label: 'Completed' },
  { value: 2, label: 'Canceled' }
];

export const PARCEL_STATUS = [
  { value: 0, label: 'Open', color: '#17CB75', background: '#E3F5ED' },
  { value: 1, label: 'In Progress', color: '#FFA53B', background: '#F9EFE3' },
  { value: 2, label: 'Overdue', color: '#573BFF', background: '#E2EAFF' },
  { value: 3, label: 'Closed', color: '#FF3B5E', background: '#FEF1F4' },
];



export const MAINTENANCE_STATUS = [
  { value: 0, label: 'Open', color: '#119C59', background: '#E3F5ED' },
  { value: 1, label: 'In Progress', color: '#DA7805', background: '#F9EFE3' },
  { value: 2, label: 'Overdue', color: '#0045FF', background: '#E2EAFF' },
  { value: 3, label: 'Closed', color: '#FF3B5E', background: '#FEF1F4' },
];

export const EVENT_STATUS = [
  { value: 1, label: 'Scheduled' },
  { value: 2, label: 'Expired' },
  { value: 3, label: 'Canceled' }
];
export const EVENT_CATEGORIES = [
  { id: 1, name: 'Online' },
  { id: 2, name: 'In Person' }
];

export const POLL_STATUS = [
  { value: 0, label: 'Draft' },
  { value: 1, label: 'Published' },
  { value: 2, label: 'Finished' },
  { value: 3, label: 'Expired' }
];




export const FILTER_OPTION = {
  "THIS_MONTH": 'This month',
  "TODAY": 'Today',
  "YESTERDAY": 'Yesterday',
  "LAST_7_DAYS": 'Last 7 days',
  "LAST_30_DAYS": 'Last 30 days',
  "LAST_MONTH": 'Last month'
}

export const PAGINATION_LIMIT = 10;

const a = 70;
export const TAG_LEFT = {
  '00:00 am': `0px`,
  '12:30 am': `${a * 1}px`,
  '01:00 am': `${a * 2}px`,
  '01:30 am': `${a * 3}px`,
  '02:00 am': `${a * 4}px`,
  '02:30 am': `${a * 5}px`,
  '03:00 am': `${a * 6}px`,
  '03:30 am': `${a * 7}px`,
  '04:00 am': `${a * 8}px`,
  '04:30 am': `${a * 9}px`,
  '05:00 am': `${a * 10}px`,
  '05:30 am': `${a * 11}px`,
  '06:00 am': `${a * 12}px`,
  '06:30 am': `${a * 13}px`,
  '07:00 am': `${a * 14}px`,
  '07:30 am': `${a * 15}px`,
  '08:00 am': `${a * 16}px`,
  '08:30 am': `${a * 17}px`,
  '09:00 am': `${a * 18}px`,
  '09:30 am': `${a * 19}px`,
  '10:00 am': `${a * 20}px`,
  '10:30 am': `${a * 21}px`,
  '11:00 am': `${a * 22}px`,
  '11:30 am': `${a * 23}px`,
  '12:00 pm': `${a * 24}px`,
  '12:30 pm': `${a * 25}px`,
  '01:00 pm': `${a * 26}px`,
  '01:30 pm': `${a * 27}px`,
  '02:00 pm': `${a * 28}px`,
  '02:30 pm': `${a * 29}px`,
  '03:00 pm': `${a * 30}px`,
  '03:30 pm': `${a * 31}px`,
  '04:00 pm': `${a * 32}px`,
  '04:30 pm': `${a * 33}px`,
  '05:00 pm': `${a * 34}px`,
  '05:30 pm': `${a * 35}px`,
  '06:00 pm': `${a * 36}px`,
  '06:30 pm': `${a * 37}px`,
  '07:00 pm': `${a * 38}px`,
  '07:30 pm': `${a * 39}px`,
  '08:00 pm': `${a * 40}px`,
  '08:30 pm': `${a * 41}px`,
  '09:00 pm': `${a * 42}px`,
  '09:30 pm': `${a * 43}px`,
  '10:00 pm': `${a * 44}px`,
  '10:30 pm': `${a * 45}px`,
  '11:00 pm': `${a * 46}px`,
  '11:30 pm': `${a * 47}px`,


}


export const TAG_TOP = {
  0: '40px',
  1: '155px',
  2: '260px',
  3: '362px',
  4: '475px',
  5: '585px',
  6: '685px',
  7: '795px',
  8: '900px',
  9: '1015px'
}

export const USER_TYPE = {
  1: 'Super Admin',
  2: 'Client Admin',
  3: 'Building Admin',
  4: 'Building User',
}

export const WARRANTY_PARAMETER = {
  1: 'day',
  2: 'month',
  3: 'year',
}

export const TIME_OPTION = [
  { value: null, label: 'Select'},
  { value: 540, label: '09:00 am' },
  { value: 570, label: '09:30 am' },
  { value: 600, label: '10:00 am' },
  { value: 630, label: '10:30 am' },
  { value: 660, label: '11:00 am' },
  { value: 690, label: '11:30 am' },
  { value: 720, label: '12:00 pm' },
  { value: 750, label: '12:30 pm' },
  { value: 780, label: '01:00 pm' },
  { value: 810, label: '01:30 pm' },
  { value: 840, label: '02:00 pm' },
  { value: 870, label: '02:30 pm' },
  { value: 900, label: '03:00 pm' },
  { value: 930, label: '03:30 pm' },
  { value: 960, label: '04:00 pm' },
  { value: 990, label: '04:30 pm' },
  { value: 1020, label: '05:00 pm' },
  { value: 1050, label: '05:30 pm' },
  { value: 1080, label: '06:00 pm' },
  { value: 1110, label: '06:30 pm' },
  { value: 1140, label: '07:00 pm' },
  { value: 1170, label: '07:30 pm' },
  { value: 1200, label: '08:00 pm' },
  { value: 1230, label: '08:30 pm' },
  { value: 1260, label: '09:00 pm' },
  { value: 1290, label: '09:30 pm' },
  { value: 1320, label: '10:00 pm' },
  { value: 1350, label: '10:30 pm' },
  { value: 1380, label: '11:00 pm' },
  { value: 1410, label: '11:30 pm' },
  { value: 0, label: '00:00 am' },
  { value: 30, label: '00:30 am' },
  { value: 60, label: '01:00 am' },
  { value: 90, label: '01:30 am' },
  { value: 120, label: '02:00 am' },
  { value: 150, label: '02:30 am' },
  { value: 180, label: '03:00 am' },
  { value: 210, label: '03:30 am' },
  { value: 240, label: '04:00 am' },
  { value: 270, label: '04:30 am' },
  { value: 300, label: '05:00 am' },
  { value: 330, label: '05:30 am' },
  { value: 360, label: '06:00 am' },
  { value: 390, label: '06:30 am' },
  { value: 420, label: '07:00 am' },
  { value: 450, label: '07:30 am' },
  { value: 480, label: '08:00 am' },
  { value: 510, label: '08:30 am' }, 
];

export const TIME_OPTIONs = [

  { value: 540, label: '09:00 am' },
  { value: 570, label: '09:30 am' },
  { value: 600, label: '10:00 am' },
  { value: 630, label: '10:30 am' },
  { value: 660, label: '11:00 am' },
  { value: 690, label: '11:30 am' },
  { value: 720, label: '12:00 pm' },
  { value: 750, label: '12:30 pm' },
  { value: 780, label: '01:00 pm' },
  { value: 810, label: '01:30 pm' },
  { value: 840, label: '02:00 pm' },
  { value: 870, label: '02:30 pm' },
  { value: 900, label: '03:00 pm' },
  { value: 930, label: '03:30 pm' },
  { value: 960, label: '04:00 pm' },
  { value: 990, label: '04:30 pm' },
  { value: 1020, label: '05:00 pm' },
  { value: 1050, label: '05:30 pm' },
  { value: 1080, label: '06:00 pm' },
  { value: 1110, label: '06:30 pm' },
  { value: 1140, label: '07:00 pm' },
  { value: 1170, label: '07:30 pm' },
  { value: 1200, label: '08:00 pm' },
  { value: 1230, label: '08:30 pm' },
  { value: 1260, label: '09:00 pm' },
  { value: 1290, label: '09:30 pm' },
  { value: 1320, label: '10:00 pm' },
  { value: 1350, label: '10:30 pm' },
  { value: 1380, label: '11:00 pm' },
  { value: 1410, label: '11:30 pm' },
  { value: 0, label: '00:00 am' },
  { value: 30, label: '00:30 am' },
  { value: 60, label: '01:00 am' },
  { value: 90, label: '01:30 am' },
  { value: 120, label: '02:00 am' },
  { value: 150, label: '02:30 am' },
  { value: 180, label: '03:00 am' },
  { value: 210, label: '03:30 am' },
  { value: 240, label: '04:00 am' },
  { value: 270, label: '04:30 am' },
  { value: 300, label: '05:00 am' },
  { value: 330, label: '05:30 am' },
  { value: 360, label: '06:00 am' },
  { value: 390, label: '06:30 am' },
  { value: 420, label: '07:00 am' },
  { value: 450, label: '07:30 am' },
  { value: 480, label: '08:00 am' },
  { value: 510, label: '08:30 am' },
];

// export const OPTION_TIME = {
//   0: '00:00 am',
//   30: '00:30 am',
//   60: '01:00 am',
//   60: '01:00 am',
//   60: '01:00 am',
//   60: '01:00 am',
//   60: '01:00 am',
//   60: '01:00 am',
//   60: '01:00 am',
//   60: '01:00 am',
//   60: '01:00 am',
//   60: '01:00 am',
//   60: '01:00 am',
//   60: '01:00 am',
//   60: '01:00 am',
//   60: '01:00 am',
//   60: '01:00 am',
//   60: '01:00 am',
//   540: '09:00 am',
//   1260: '09:00 pm'
// }

// export const STATUS_COLOR = {
//   0: '00:00 am',
//   30: '00:30 am',
//   60: '01:00 am',
//   60: '01:00 am',
//   60: '01:00 am',
//   60: '01:00 am',
//   60: '01:00 am',
//   60: '01:00 am',
//   60: '01:00 am',
//   60: '01:00 am',
//   60: '01:00 am',
//   60: '01:00 am',
//   60: '01:00 am',
//   60: '01:00 am',
//   60: '01:00 am',
//   60: '01:00 am',
//   60: '01:00 am',
//   60: '01:00 am',
//   540: '09:00 am',
//   1260: '09:00 pm'
// }

export function stripHtmlTags(str, length) {
  if ((str === null) || (str === '')) {
    return false;
  } else {
    str = str.toString();
    str = str.replace(/<[^>]*>/g, '')
    if (length && length > 0 && str.length > length) {
      str = str.substr(0, length);
      str = str + "...";
    }
  }
  return str;
}

export const HEAT_MAP_OPTION = {
  '0': '12AM',
  '1': '1AM',
  '2': '2AM',
  '3': '3AM',
  '4': '4AM',
  '5': '5AM',
  '6': '6AM',
  '7': '7AM',
  '8': '8AM',
  '9': '9AM',
  '10': '10AM',
  '11': '11AM',
  '12': '12PM',
  '13': '1PM',
  '14': '2PM',
  '15': '3PM',
  '16': '4PM',
  '17': '5PM',
  '18': '6PM',
  '19': '7PM',
  '20': '8PM',
  '21': '9PM',
  '22': '10PM',
  '23': '11PM',
}

export const USER_STATUS = {
  1: 'ACTIVE',
  0: 'INACTIVE',
  2: "DISABLED"
}
export const CARD_STATUS = {
  1: 'ACTIVE',
  0: 'Get Access Card',
  2: "INACTIVE"
}

export const COLOR_OPTION = {
  0: 'info-blk',
  1: 'info-blk bg-color-1',
  2: 'info-blk bg-color-1',
  3: 'info-blk bg-color-1',
  4: 'info-blk bg-color-2',
  5: 'info-blk bg-color-2',
  6: 'info-blk bg-color-2',
  7: 'info-blk bg-color-3',
  8: 'info-blk bg-color-3',
  9: 'info-blk bg-color-3',
  10: 'info-blk bg-color-4',
  11: 'info-blk bg-color-4',
  12: 'info-blk bg-color-4',
  13: 'info-blk bg-color-5',
  14: 'info-blk bg-color-5',
  15: 'info-blk bg-color-5',
  16: 'info-blk bg-color-6',
  17: 'info-blk bg-color-6',
  18: 'info-blk bg-color-6',
  19: 'info-blk bg-color-7',
  20: 'info-blk bg-color-7',
  21: 'info-blk bg-color-7',
  22: 'info-blk bg-color-8',
  23: 'info-blk bg-color-8',
  24: 'info-blk bg-color-8',
  25: 'info-blk bg-color-9',
  26: 'info-blk bg-color-9',
  27: 'info-blk bg-color-9',
  28: 'info-blk bg-color-9',
  29: 'info-blk bg-color-9',
  30: 'info-blk bg-color-9',
  31: 'info-blk bg-color-9',
  32: 'info-blk bg-color-9',
  33: 'info-blk bg-color-9',
  34: 'info-blk bg-color-9',
  35: 'info-blk bg-color-9',
  36: 'info-blk bg-color-9',
  37: 'info-blk bg-color-9',
  38: 'info-blk bg-color-9',
  39: 'info-blk bg-color-9',
  40: 'info-blk bg-color-9',




}

export function parseISOString(s) {
    var b = s.split(/\D+/);
    return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
}
export const LANGUAGES_OPTION = [
  { id: 1, language_code: 'en', language_name: 'English' },
  { id: 2, language_code: 'ar', language_name: 'Arabic' },
];
export const format = (e, command, value = null) => {
  e.preventDefault();
  document.execCommand(command, false, value);
}

export function removeHtmlTags(html) {
  var html = html.replaceAll("<br>","||br||");  
  console.log('httml', html)
  var tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  html = tmp.textContent||tmp.innerText;
  return html.replaceAll("||br||","<br>");  
}

export const customMapStyle = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#dadada",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#c9c9c9",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
];

export const markerURL = "/assets/images/seprite.png";

export const phoneMask = createTextMask({
  pattern: '9999999999',
});

export const  getFileExtension = (filename) => {

  // get file extension
  const extension = filename.split('.').pop();
  return extension?.toLowerCase();
}

export const imageExtensions = [
  'jpg',
  'gif',
  'jpeg',
  'png'
]
  
export const REPORT_TYPES = [
  { value: 1, label: 'Tickets' },
  { value: 2, label: 'Bookings' },
  { value: 3, label: 'Events' },
  { value: 4, label: 'Parcels' },
  { value: 5, label: 'Polls' },
];


